import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">
      <path d="M3421 7560 c-140 -30 -285 -170 -306 -297 -15 -93 47 -207 139 -256
33 -18 58 -22 126 -22 77 1 92 4 162 38 121 59 198 162 206 278 5 70 -11 122
-54 175 -58 72 -168 106 -273 84z"/>
<path d="M5425 7164 c-104 -30 -203 -57 -220 -62 -32 -9 -44 -33 -80 -164
l-16 -57 -67 -3 -67 -3 -17 -64 c-18 -67 -28 -87 -28 -55 0 26 -36 92 -69 128
-67 73 -250 108 -376 71 -99 -28 -181 -76 -263 -152 -14 -13 -28 -21 -32 -18
-4 2 -10 31 -13 64 -3 33 -8 64 -12 70 -10 17 -486 15 -507 -1 -9 -8 -27 -63
-43 -138 -15 -69 -29 -132 -31 -141 -4 -13 5 -18 41 -23 25 -4 45 -9 45 -11 0
-9 -131 -531 -141 -562 -11 -36 -29 -42 -49 -18 -8 9 -30 15 -56 15 -36 0 -44
3 -44 18 0 10 18 83 40 162 83 304 121 436 131 465 6 17 23 77 39 135 20 76
25 107 16 112 -6 4 -49 8 -96 8 -47 0 -189 3 -316 7 l-231 6 -10 -39 c-6 -21
-29 -106 -52 -189 -22 -82 -41 -153 -41 -157 0 -5 23 -8 51 -8 43 0 50 -2 45
-17 -5 -19 -100 -380 -117 -445 -10 -38 -10 -38 -62 -38 l-52 0 -49 -185 c-27
-102 -48 -187 -45 -189 2 -2 123 -9 269 -15 146 -6 313 -14 371 -17 103 -6
107 -6 114 15 6 21 8 21 360 21 l355 0 6 28 c3 15 19 80 35 144 42 168 45 158
-56 158 -47 0 -85 2 -85 4 0 13 92 370 105 406 37 105 113 170 198 170 63 0
97 -30 104 -90 4 -45 -11 -111 -141 -610 -25 -96 -46 -183 -46 -192 0 -17 21
-18 310 -18 l310 0 6 28 c12 57 15 57 41 7 13 -28 29 -55 34 -62 38 -45 211
-78 328 -62 221 29 222 29 236 63 19 43 74 274 75 312 l0 20 -81 -20 c-74 -18
-84 -18 -108 -5 -37 22 -38 17 54 359 8 30 22 84 32 120 l19 65 57 3 c31 2 57
0 57 -3 0 -3 -10 -36 -22 -73 -32 -97 -32 -276 1 -382 34 -111 78 -186 152
-260 112 -112 251 -170 409 -170 46 0 79 -4 76 -9 -3 -5 -37 -19 -76 -30 -128
-39 -466 -182 -714 -301 -101 -49 -111 -52 -141 -41 -88 32 -224 23 -343 -21
-48 -18 -53 -23 -50 -46 3 -25 7 -27 48 -27 25 0 55 4 68 8 19 7 22 6 18 -15
-3 -13 -5 -28 -5 -35 -1 -10 -54 -40 -142 -81 -13 -6 -29 156 -31 295 l-1 142
-36 34 c-56 53 -69 57 -210 57 -175 0 -171 4 -171 -168 l0 -128 -54 -17 c-65
-21 -127 -79 -144 -135 -36 -119 16 -272 103 -303 19 -7 35 -16 35 -20 0 -4
-56 -25 -124 -48 l-124 -41 -712 0 c-704 -1 -780 3 -780 32 0 4 -9 8 -20 8
-11 0 -20 4 -20 9 0 8 117 31 231 46 33 4 77 -2 149 -20 119 -29 206 -32 270
-8 55 20 243 23 278 4 29 -16 263 -4 311 15 18 8 53 14 77 14 23 0 46 5 49 10
3 6 14 10 24 10 10 0 36 21 56 46 l38 46 -7 276 c-5 211 -10 280 -20 290 -7 8
-67 29 -132 48 -108 31 -130 34 -250 34 -112 0 -133 -3 -146 -17 -12 -14 -18
-51 -22 -148 -3 -71 -6 -140 -7 -152 -1 -22 -5 -23 -79 -23 -57 0 -81 4 -90
15 -10 13 -9 15 10 15 13 0 21 2 19 4 -7 6 -78 15 -179 22 -83 6 -99 10 -122
32 -23 21 -34 24 -75 20 -27 -3 -72 -17 -102 -31 -41 -21 -67 -27 -117 -27
-60 0 -65 2 -74 26 -6 14 -10 43 -10 64 0 21 -4 41 -8 44 -8 5 -214 -63 -295
-97 -32 -14 -40 -25 -36 -53 3 -24 -100 -33 -123 -11 -7 7 -28 19 -46 27 -31
12 -33 16 -28 44 3 17 9 49 12 71 4 22 11 46 16 52 15 19 -22 75 -57 86 -17 5
-78 8 -136 8 -58 -1 -170 6 -250 14 -128 15 -149 20 -188 45 -58 39 -97 45
-146 25 -59 -24 -95 -76 -95 -137 0 -85 46 -141 128 -154 84 -13 172 63 172
149 0 15 6 30 13 33 18 7 254 -23 294 -36 27 -10 31 -16 37 -63 3 -29 9 -70
12 -93 l6 -41 -39 -6 c-21 -4 -65 -7 -99 -7 -48 0 -67 -5 -94 -25 -43 -32 -79
-32 -116 0 -28 24 -34 25 -169 25 -96 0 -145 -4 -153 -12 -9 -9 -12 -74 -12
-229 0 -189 2 -218 17 -230 13 -11 88 -14 377 -16 331 -1 364 0 391 17 50 29
83 33 90 10 7 -21 46 -37 64 -25 6 3 11 19 11 36 0 27 2 29 40 29 30 0 51 -9
86 -35 25 -19 53 -35 62 -35 9 0 37 -10 62 -22 l45 -21 -105 -18 c-93 -16
-167 -18 -637 -17 -292 0 -536 -3 -542 -6 -6 -4 -11 -13 -11 -21 0 -25 123
-28 787 -21 l653 6 72 -26 c256 -92 400 -118 640 -116 220 2 454 41 653 109
l80 27 1040 4 c572 2 1149 0 1283 -3 218 -7 242 -9 242 -24 0 -36 20 -41 170
-41 150 0 170 5 170 41 0 10 10 21 23 25 12 4 272 5 577 3 305 -2 786 -3 1068
-1 483 2 513 3 510 20 -2 13 -18 19 -63 25 -33 4 -355 6 -715 5 -360 -1 -820
-1 -1022 0 l-368 2 0 35 c0 20 6 37 13 39 91 25 115 26 212 10 78 -13 141 -15
289 -11 138 4 213 2 275 -9 120 -19 182 -18 282 8 53 13 92 19 105 14 10 -5
64 -10 119 -12 55 -2 116 -8 135 -14 56 -16 123 -12 199 14 114 39 131 67 152
265 13 126 31 161 84 161 18 0 25 5 25 20 0 21 -29 32 -139 51 -50 9 -84 9
-149 -1 -46 -7 -181 -13 -299 -14 -175 -2 -218 0 -236 13 -12 8 -63 45 -112
81 -135 100 -228 157 -350 216 -109 53 -279 114 -316 114 -23 0 -23 1 -5 115
8 50 16 108 18 130 l3 40 -45 5 c-32 4 -46 10 -48 22 -4 20 11 138 108 882 16
127 27 232 24 235 -6 7 -452 2 -475 -5 -12 -3 -18 -31 -27 -132 -17 -175 -17
-173 11 -168 45 8 47 0 31 -154 -9 -80 -28 -260 -42 -400 l-27 -255 -34 -5
c-42 -6 -46 -18 -56 -153 -4 -52 -10 -99 -14 -104 -7 -11 -141 -32 -141 -22 0
4 8 10 18 13 9 4 41 21 70 39 l52 31 0 56 c-1 55 -1 55 -85 150 l-84 95 84 3
85 3 0 221 0 221 -30 51 c-66 116 -204 212 -360 252 -87 22 -236 22 -331 -1
-73 -18 -201 -75 -214 -95 -14 -22 -23 -8 -15 23 4 17 11 49 15 70 l6 37 -106
0 c-81 0 -105 3 -105 14 0 7 11 58 24 112 14 54 29 125 34 157 8 49 7 57 -7
56 -9 -1 -101 -25 -206 -55z m-425 -656 c0 -7 -29 -119 -64 -248 l-63 -235
-32 -3 c-22 -2 -31 1 -31 12 0 14 105 446 115 474 2 6 20 12 40 12 21 0 35 -5
35 -12z m1277 -41 c12 -12 23 -33 25 -47 l3 -25 -127 -3 c-147 -3 -150 -1 -92
76 1 2 20 10 41 18 50 18 121 9 150 -19z m243 -376 c0 -5 -37 -27 -83 -51 -71
-35 -95 -42 -162 -47 -46 -3 -98 -1 -122 6 -45 12 -103 60 -103 85 0 14 26 16
235 16 129 0 235 -4 235 -9z m556 -412 c107 -10 240 -40 359 -81 97 -33 97
-37 -1 -38 -102 0 -122 -9 -139 -61 -10 -29 -18 -35 -51 -40 -30 -5 -44 -2
-63 12 -33 26 -115 26 -158 -1 -37 -22 -63 -76 -63 -131 0 -56 -17 -66 -117
-73 -49 -4 -111 -11 -139 -18 -29 -6 -57 -8 -63 -4 -6 4 -42 0 -80 -9 -62 -15
-71 -16 -98 -2 -76 40 -202 44 -343 12 -36 -8 -86 -18 -112 -21 -41 -4 -48 -8
-48 -27 0 -12 9 -28 19 -34 16 -10 18 -18 11 -42 -6 -21 -5 -31 3 -35 7 -2 -1
-5 -17 -5 -56 -2 -109 -89 -92 -154 3 -13 15 -34 26 -47 23 -27 20 -35 -10
-25 -31 10 -40 44 -40 151 l0 94 50 0 c49 0 50 1 50 30 0 17 -6 31 -12 31 -7
1 -29 2 -48 3 l-35 1 -3 118 c-2 84 -6 119 -15 123 -7 2 -33 -2 -57 -11 -45
-16 -66 -14 -33 3 51 28 274 115 393 155 351 117 629 155 926 126z m672 -232
c113 -68 244 -166 236 -178 -3 -5 -67 -10 -142 -10 -75 -1 -127 -4 -114 -6 15
-3 22 -10 20 -21 -2 -13 -16 -18 -50 -20 l-47 -3 -3 141 c-3 130 -2 150 9 150
2 0 43 -24 91 -53z m-448 -95 c0 -92 -33 -96 -44 -5 -6 44 -4 52 11 55 28 7
33 0 33 -50z m-1827 -110 c-12 -9 -30 -36 -40 -59 -10 -24 -21 -43 -25 -43 -7
0 -48 54 -48 64 0 8 101 54 120 55 10 0 8 -5 -7 -17z m-2656 -88 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1593 -182 l0 -58 -51 -16 -50 -17
-20 32 c-10 18 -19 44 -19 60 0 15 -1 30 -2 35 -3 7 55 17 115 20 27 2 27 1
27 -56z m601 26 c1 -62 11 -128 22 -145 5 -9 2 -13 -10 -14 -38 -3 -59 4 -81
24 -19 18 -23 30 -20 72 3 46 6 51 38 67 45 23 50 23 51 -4z m439 -94 c8 -26
29 -62 54 -88 36 -39 48 -45 107 -55 36 -6 90 -11 121 -11 68 0 218 45 218 66
0 20 11 18 83 -13 55 -23 80 -27 217 -33 125 -5 171 -11 238 -32 46 -14 86
-29 89 -32 4 -3 -484 -6 -1083 -6 -599 -1 -1115 -2 -1147 -4 -68 -3 -65 2 23
40 59 25 79 28 225 33 158 6 161 6 198 35 22 17 43 41 49 55 l9 26 16 -26 c42
-69 117 -89 337 -89 180 0 196 6 196 73 0 115 26 146 50 61z m-2607 -41 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-46 -9 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-267 -144 c11 -7 1 -9 -35 -9 -44 1 -46
2 -20 9 17 4 32 8 35 9 3 0 12 -4 20 -9z m1415 -69 c-10 -9 -176 -50 -290 -71
-110 -21 -417 -24 -541 -6 -112 16 -281 60 -298 77 -6 5 215 9 564 9 334 0
570 -4 565 -9z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
